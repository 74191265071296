import { isType } from "@/utils/common.utils";
// Form input fields validators

/**
 * Validates required input fields
 * @param {String} val Input field value
 * @param {String} fieldName Input field name to be validated
 * @returns {Function}
 */
export const required = (fieldName, errorMessage = null) => {
  return (val) => !!val || errorMessage || `${fieldName} is required.`;
};

/**
 * Validate email field
 * @param {String} val Input field value
 * @return {Function}
 */
export const email = (value) => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value) || "Please enter a valid email address.";
};

/**
 * Accept only alphabet and space
 * @param {String} value input field value
 * @returns {Functions}
 */
export const letterOnly = (value) => {
  const pattern = /^[A-Za-z ]+$/;
  return (
    pattern.test(value) || `Special characters and numbers are not allowed`
  );
};

/**
 * Validates required input fields for multiple values
 * @param {String} val Input field value
 * @param {String} fieldName Input field name to be validated
 * @returns {Function}
 */
export const requiredMultiple = (fieldName) => {
  return (val) => !!(val && val.length > 0) || `${fieldName} is required`;
};

/**
 * Validates input field maxlength
 * @param {String} fieldname Field name to be validated
 * @param {Number} length Maxlenght of the field to be validated
 * @returns {Function}
 */
export const maxLength = (length, fieldname, isRequired = true) => {
  return (val) => {
    let errMsg = isType(val, "string")
      ? `${fieldname} must be less than ${length} characters`
      : null;

    if (!isRequired && !val) {
      return true;
    }

    return (
      val?.length <= length ||
      (errMsg ?? `Selected ${fieldname} must be ${length}`)
    );
  };
};
/**
 * Validates input field minlength of the selected values
 * @param {String} fieldname Field name to be validated
 * @param {Number} length Minlenght of the field to be validated
 * @returns {Function}
 */
export const minLength = (length, fieldname) => {
  return (val) => {
    let errMsg = isType(val, "string")
      ? `${fieldname} must be less than ${length} characters`
      : null;

    return (
      (val && val.length >= length) ||
      (errMsg ?? `Selected ${fieldname} must be ${length}`)
    );
  };
};

/**
 * Validates numeric values of the input field
 * @param {String} fieldName Field name to be validated
 * @returns {Boolean | String}
 */
export const numeric = (fieldName) => {
  const pattern = /^(0|[1-9][0-9]*)$/;

  return (val) => {
    if (!val) {
      return true;
    }
    return pattern.test(val) || `${fieldName} should be a number`;
  };
};

/**
 * Validates the number input field for the minimun value
 * @param {Number} length
 * @param {String} fieldName
 */
export const minLengthNumber = (length, fieldName) => {
  return (val) =>
    (isFinite(val) && val >= length) ||
    `${fieldName} must be greater than ${length}`;
};

/**
 * Validates the number input field for the maximum value
 * @param {Number} length
 * @param {String} fieldName
 */
export const maxLengthNumber = (length, fieldName) => {
  return (val) =>
    (isFinite(val) && val <= length) ||
    `${fieldName} must be lesser than ${length}`;
};

/**
 * Validates the input field value for alpha numeric values only
 * @param {String} fieldName Name of the fieldname to bevalidates
 * @returns {Function}
 */
export const alphaNumeric = (fieldName) => {
  const validator = /^[a-zA-Z0-9_ ]*$/;
  return (val) =>
    validator.test(val) || `${fieldName} should have alpha numeric values`;
};

/**
 * Validates only special characters
 * @param {String} value Value to be validated
 * @returns
 */
export const specialCharacters = (value) => {
  /** Regex to validate special charcters only */
  const SPECIAL_CHARCTER_REGEX = /[!@#$%^&*(),.?":{}|<>]/g;
  return value && SPECIAL_CHARCTER_REGEX.test(value);
};

/**
 * Validates a domain name
 * @param {String} fieldName Name of the field to be validated
 * @returns {Function}
 */
export const validateDomain = (fieldName) => {
  const REGEX = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
  return (val) => REGEX.test(val) || `${fieldName} is not valid.`;
};

/**
 * Validates IP address entered by the user
 * @param {String} fieldName Name of the field to be validated
 * @returns {Function}
 */
export function validateIPaddress(fieldName) {
  var REGEX = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return (val) => REGEX.test(val) || `${fieldName} is not valid.`;
}
