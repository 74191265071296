/**
 * App ui configurations
 */
const appConfig = {
  // Snackbar configuration
  snackbar: {
    timeout: 10000,
    successBgColor: "primary",
    infoBgColor: "#f7d36f",
    errorBgColor: "#ff3232",

    // Snackbar configuration
    config: {
      success: {
        icon: "mdi-check-circle-outline",
        bgColor: "#BCECC8",
        iconColor: "#29993E",
      },
      error: {
        icon: "mdi-information-outline",
        bgColor: "rgba(239, 197, 202, 1)",
        iconColor: "#B00020",
      },
      info: {
        icon: "mdi-information-outline",
        bgColor: "#f7d36f",
        iconColor: "#ffffff",
      },
      close: {
        icon: "mdi-close",
        bgColor: "inherit",
        iconColor: "rgba(0, 0, 0, 0.6)",
      },
    },
    // Types of the snackbar
    snackbarTypes: {
      success: "success",
      error: "error",
      info: "info",
    },

    icons: {
      error: "mdi-close",
    },
    iconColor: "#fffff",
  },

  /**
   * Bread crumb component config
   */
  breadcrumbs: {
    types: {
      // Use @type route to render the route with link to the page
      route: "route",
      // Use @type name to render the account name or any string related to that view
      name: "name",
    },
  },

  loader: {
    color: "primary",
    size: 70,
    medium: 60,
    small: 40,
  },

  /**
   * Api response status names
   */
  responseStatus: {
    success: "success",
    error: "error",
  },
  /**
   * Types of threshold metrics to be shown in the (Campaign, Mailbox provider) index pages
   */
  thresholds: {
    inbox: "inbox",
    spam: "spam",
    missing: "missing",
    textColor: "rgba(219, 0, 0, 1)",
    backgroundColor: "rgba(219, 0, 0, 0.08)",
  },
  maropostEmailDomain: "maropost.com",
  /**
   * Inbox aware commercial site details
   */
  inboxAwareSite: {
    domain: "https://inboxaware.com",
  },
  /** Csv button types */
  csvButton: {
    color: {
      primary: "primary",
      secondary: "secondary",
    },
  },
  /** Tooltip */
  tooltip: {
    type: {
      default: "default",
      dark: "dark",
    },
    colors: {
      default: "",
      dark: "rgba(0, 0, 0, 0.87)",
    },
  },
  // Alert
  alert: {
    colors: {
      error: "rgba(239, 197, 202, 1)",
      errorIcon: "rgba(176, 0, 32, 1)",
      info: "#F2F2F2",
      infoIcon: "rgba(0, 0, 0, 0.87)",
    },
  },
  // Api documentation
  apiDocs: {
    name: "API Documentation",
    href: process.env.VUE_APP_API_DOCS_URL,
  },
  defaultLocale: "en-US",
  /**
   * Application layouts
   * @description Add new layouts property values here
   */
  layouts: {
    app: "App",
    auth: "Auth",
  },
  // Inboxaware support email
  supportEmail: "support@inboxaware.com",
  // Mountly blocklist monitoring quota
  monthlyBlocklistQuota: 1000,
};

appConfig.inboxAwareSite.termsConditions = `${appConfig.inboxAwareSite.domain}/terms-conditions`;
appConfig.inboxAwareSite.privacyPolicy = `${appConfig.inboxAwareSite.domain}/privacy-policy`;

export default Object.freeze(appConfig);
