/**
 * Vuetify colors scheme to be used in the layout
 */
const vuetifyColors = {
  green: "#41CE65",
  white: "#FFFFFF",
  grey: "#BBBBBB",

  black: "#1E2533",
  lightGrey: "#f5f5f5",
  background: "#FBFBFB",
  darkBlue: "#104FFB",
  darkRed: "#E02020",
  blue: "#006BAF",
  error: "#B00020",
};

/**
 * Vuetify global theme configuration for the inboxaware application
 */
const vuetifyConfig = {
  theme: {
    dark: false,
    light: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: vuetifyColors.green,
        anchor: vuetifyColors.white,
        white: vuetifyColors.white,
        grey: vuetifyColors.grey,
        "light-grey": vuetifyColors.lightGrey,
        background: vuetifyColors.background,
        black: vuetifyColors.black,
        darkBlue: vuetifyColors.darkBlue,
        "dark-red": vuetifyColors.darkRed,
        blue: vuetifyColors.blue,
        error: vuetifyColors.error,
      },
    },
  },
};

export default Object.freeze(vuetifyConfig);
