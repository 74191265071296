<template>
  <v-alert
    :icon="false"
    dense
    v-if="message"
    :color="bgColor"
    class="break-word"
    :class="classes"
  >
    <template>
      <v-icon :color="iconColor">
        mdi-alert-circle-outline
      </v-icon>
    </template>
    <span class="text--primary body-2">
      {{ message }}
      <slot name="message" />
    </span>
  </v-alert>
</template>
<script>
import $appConfig from "@/config/app.config";

/**
 *  Alert Message Component
 */
export default {
  name: "AlertMessage",
  /**
   * ------- Props attributes -------
   */
  props: {
    message: { type: String, default: null },
    classes: {
      type: String,
      default: "py-3",
    },
    bgColor: { type: String, default: $appConfig.alert.colors.error },
    iconColor: { type: String, default: $appConfig.alert.colors.errorIcon },
  },
};
</script>
