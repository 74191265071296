import { getSendingIps } from "@/services/campaign";
import { sortIpAddress } from "@/utils/shared.utils";
/**
 * State of campaign module
 */
const state = {
  sendingIps: [],
};

/**
 * Mutation constants
 */
const SET_CAMPAIGN_SENDING_IPS = "SET_CAMPAIGN_SENDING_IPS";

/**
 * Module mutations
 */
const mutations = {
  [SET_CAMPAIGN_SENDING_IPS](state, data) {
    state.sendingIps = data;
  },
};

/**
 * Actions
 */
const actions = {
  /**
   * Fetches list of sending ips of a campaign
   */
  async getCampaignSendingIps({ commit }, params) {
    try {
      let { data } = await getSendingIps(params);

      data = data ?? [];
      commit(SET_CAMPAIGN_SENDING_IPS, data.sort(sortIpAddress));
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

/**
 * Getters
 */
const getters = {
  sendingIps({ sendingIps }) {
    return sendingIps;
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true,
};
