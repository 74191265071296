import { getAccount } from "@/services";
import $appConfig from "@/config/app.config";
import $paths from "@/constants/routes-path";

import { required, rejectPromise } from "@/utils";
import { updateStore } from "@/utils/common.utils";

const { snackbar } = $appConfig;

/** Initial state value if selectedAccount property */
const selectedAccountInitialState = {
  account_id: null,
  account_name: "",
  path: $paths.inboxMonitoring,
  isAccountChanged: false,
};

/**
 * State of the module
 */
const state = {
  //Details of last viewed account whose Campaigns were viewed
  campaignAccount: null,
  // Select account id whose data shown in dashboard
  selectedAccount: { ...selectedAccountInitialState },
  snackbar: {
    value: false,
    message: "",
    type: snackbar.snackbarTypes.error,
    icon: snackbar.icons.error,
  },
  hideSnackbar: false,
  isAccountChanged: false,
};

/**
 * Mutations constants
 */

const SET_ACCOUNT_CAMPAIGN = "SET_ACCOUNT_CAMPAIGN";
const SET_ACCOUNT = "SET_ACCOUNT";
const REMOVE_ACCOUNT = "REMOVE_ACCOUNT";
const SET_SNACKBAR = "SET_SNACKBAR_ERROR";
const HIDE_SNACKBAR = "HIDE_SNACKBAR";
const SET_SELECTED_ACCOUNT_CHANGED = "SET_SELECTED_ACCOUNT_CHANGED";
const SET_SELECTED_ACCOUNT_THRESHOLD = "SET_SELECTED_ACCOUNT_THRESHOLD";

/**
 *  Mutations
 */
const mutations = {
  [SET_ACCOUNT_CAMPAIGN](state, data) {
    state.campaignAccount = data;
  },
  [SET_ACCOUNT](state, data) {
    const path = `${$paths.inboxMonitoring}/${data?.id}${$paths.campaigns}`;
    const account = {
      ...data,
      name: data.account_name,
      account_id: data?.id,
      path,
    };

    state.selectedAccount = { ...state.selectedAccount, ...account };
  },
  [SET_SNACKBAR](state, data) {
    state.snackbar = { ...state.snackbar, ...data };
  },
  [REMOVE_ACCOUNT](state) {
    state.selectedAccount = { ...selectedAccountInitialState };
  },
  [SET_SELECTED_ACCOUNT_CHANGED](state, isAccountChanged) {
    state.isAccountChanged = isAccountChanged;
  },
  [SET_SELECTED_ACCOUNT_THRESHOLD](state, thresholds) {
    state.selectedAccount = updateStore(state.selectedAccount, thresholds);
  },
  [HIDE_SNACKBAR](state, data) {
    state.hideSnackbar = data;
  },
};

/**
 *  Actions of the ui module
 */
const actions = {
  /**
   * Sets account details in the store for the last account whose campaigns were viewed
   * @description commits mutation to mutate the account detailsin the store
   * @param {Object} account details whose campigns were viewed
   */
  setCampaignAccount(
    { commit },
    campaignAccount = required("campaignAccount action")
  ) {
    if (!campaignAccount) return;

    commit(SET_ACCOUNT_CAMPAIGN, campaignAccount);
  },

  async setAccount({ commit, dispatch }, selectedAccount) {
    const data = {
      account_id: selectedAccount?.id,
      name: selectedAccount.account_name,
    };
    await commit(SET_ACCOUNT, selectedAccount);

    await dispatch("getAccountDetails", data);
    // Fetches mailbox providers of the updated selected account
    dispatch("account/getMailboxProviders", data, { root: true });
  },
  /**
   * Handles global snackbar
   */
  setSnackbar({ commit }, data) {
    commit(SET_SNACKBAR, data);
  },
  /**
   * Fetches selected account details and commits a mutation to store the selected account
   */
  async getAccountDetails({ commit }, params) {
    try {
      const { data } = await getAccount(params);

      if (data) commit(SET_ACCOUNT, data);
    } catch (error) {
      rejectPromise(error);
    }
  },
};

/**
 * Module getters
 */
const getters = {
  /**
   * Return the last viewed account details whose campaign were viewed by the user
   * @returns {Object} Account details
   */
  campaignAccount({ campaignAccount }) {
    return campaignAccount;
  },
  /**
   * Return the select account id
   * @returns {String} Account Id
   */
  selectedAccount({ selectedAccount }) {
    return selectedAccount;
  },
  snackbar({ snackbar }) {
    return snackbar;
  },
  /** Current selected account threshold settings */
  selectedAccountThresholds({ selectedAccount }) {
    let thresholds = {};
    const THRESHOLD = "threshold";

    selectedAccount &&
      Object.keys(selectedAccount)
        .filter((prop) => prop.includes(THRESHOLD))
        .forEach(
          (threshold) => (thresholds[threshold] = selectedAccount[threshold])
        );

    return thresholds ?? null;
  },
  /**
   * Determines if the path passed in the param is of session expired or logged out page
   * @returns {Boolean}
   */
  isSessionExpiredRoute: () => ({ path }) => {
    const { sessionExpired, loggedOut } = $paths;

    const isAuthExpired = [sessionExpired, loggedOut].includes(path);
    return isAuthExpired;
  },
  selectedAccountId({ selectedAccount }) {
    return selectedAccount?.account_id ?? "";
  },
  hideSnackbar({ hideSnackbar }) {
    return hideSnackbar;
  },
  isAccountChanged({ isAccountChanged }) {
    return isAccountChanged;
  },
  /**
   * Blocklist moonthly lookups count of a selected account
   * @todo Fetch the real value monthly lookups from the selected account once the feature is implemented
   */
  monthlyLookupsQuota({ selectedAccount }) {
    return selectedAccount?.monthly_lookups ?? 1000;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
