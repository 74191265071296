import store from "./store";
import { required } from "@/utils";
import appConfig from "@/config/app.config";

const { defaultLocale } = appConfig;

/**
 * Formats a date in date time format
 * @param {String} dateString - the date to be formatted
 * @returns {String} formatted date
 */
export const formatDate = (dateString) => {
  if (!dateString) return;
  const date = new Date(dateString);
  const formattedDate = date.toDateString();

  return formattedDate;
};

/**
 * Calculates the amount of time between 2 dates in minutes and seconds format
 * @param {String} dateToDiffString date to differentiate
 * @param {String} dateFromDiffString date to differentiate from
 * @returns {String} duration in minutes and seconds format
 */
export const duration = (dateToDiffString, dateFromDiffString) => {
  if (!dateToDiffString || !dateFromDiffString) return "";

  const dateToDiff = new Date(dateToDiffString);
  const dateFromDiff = new Date(dateFromDiffString);

  // Difference between 2 dates in milli seconds
  const dateDiffMillis = dateFromDiff.getTime() - dateToDiff.getTime();

  return millisToMinutesAndSeconds(dateDiffMillis);
};

/**
 * Converts milliseconds into minutes and seconds format
 * @param {String} millis Milliseconds to be converted to minutes and seconds format
 * @returns {String} minutes and seconds format
 */
const millisToMinutesAndSeconds = (
  millis = required("millisToMinutesAndSeconds function")
) => {
  // Minutes
  const minutes = Math.floor(millis / 60000);
  // Seconds
  const seconds = ((millis % 60000) / 1000).toFixed(0);

  return `${minutes} min ${seconds} sec`;
};

/**
 * Calculates the percentage of the records
 * @param {Number} from Sum of the records
 * @param {Number} total total
 * @returns {Number} percentage
 */
export const percent = (from = required(), total = required()) => {
  if (!from) return 0;
  return ((from * 100) / total).toFixed(2);
};

/**
 * Fomates date in am pm format
 * @param {String} Date in string format
 * @returns {String} Date formatted in ( Feb 9, 2021 at 7:18 am ) format
 */
export const formatDateToAmPm = function(value) {
  if (!value) return value;

  const preferredTimeZone = store.getters["auth/currentUserProfile"]?.time_zone;

  let date = new Date(value);

  if (preferredTimeZone) {
    date = new Date(
      date.toLocaleString(defaultLocale, { timeZone: preferredTimeZone })
    );
  }

  const dateFormat = preferredTimeZone
    ? `${date.getMonthName()} ${date.getDate()}, ${date.getFullYear()} at ${date.formatAmPm(
        false
      )}`
    : `${date.getMonthName()} ${date.getUTCDate()}, ${date.getUTCFullYear()} at ${date.formatAmPm()}`;

  return dateFormat;
};

/**
 * Returns the substring of a string value
 * @param {String} value String to be truncated
 * @param {Number} length Number of string characters to be truncated
 * @returns {String} Truncated string
 */ export const truncateText = function(value, length) {
  if (!length || !value) return value;

  const isValueEqualOrGreater = value.length >= length;
  let subString = value?.substr(0, length);

  return isValueEqualOrGreater ? `${subString}...` : value;
};

/**
 * Capitalizes the first character of the string
 * @param {String} str String to be capitalised
 * @returns {String} Capitalized string
 */
export const capitalize = (str) => {
  if (typeof str !== "string") return "";

  const capitalizedStr = `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  return capitalizedStr;
};
