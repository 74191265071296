import { $accounts } from "@/services/apis/accounts";
import apiEndpoints from "@/constants/api-endpoints";

/**
 * Fetches list of blocklist monitoring profiles of an account
 * @param {String} account_id
 * @returns {Promise} List of monitoring profiles
 */
export const blocklistProfiles = ({ account_id, ...params }) => {
  const endpoint = `${account_id}${apiEndpoints.blocklistProfiles}`;
  return $accounts.get(endpoint, { params });
};

/**
 * Updates block list monitoring profile details
 * @param {String} accountId Account id whose blocklist monitoring profile have to be updated
 * @param {Object} payload Updated profile details
 * @returns {Profile} Contains updated profile details
 */
export const updateBlocklistProfile = (accountId, payload, profileId) => {
  return $accounts.put(
    `${accountId}${apiEndpoints.blocklistProfiles}/${profileId}`,
    payload
  );
};

/**
 * Creates block list monitoring profile details
 * @param {String} accountId Account id whose blocklist monitoring profile have to be updated
 * @param {Object} payload
 * @returns {Profile} Contains crested profile details
 */
export const createBlocklistProfile = (accountId, payload) => {
  return $accounts.post(
    `${accountId}${apiEndpoints.blocklistProfiles}`,
    payload
  );
};

/**
 * Deletes block list monitoring profile
 * @param {String} accountId Account id whose blocklist monitoring profile is to be deleted
 * @param {Object} id Blocklist monitoring profile id
 * @returns {Profile} 204 status code is profile have been deleted
 */
export const deleteBlocklistProfile = (accountId, id) => {
  return $accounts.delete(
    `${accountId}${apiEndpoints.blocklistProfiles}/${id}`
  );
};

/**
 * Fetch block list monitoring profile
 * @param {String} accountId Account id whose blocklist monitoring profile is to be deleted
 * @param {Object} id Blocklist monitoring profile id
 * @returns {Profile}
 */
export const getBlocklistProfile = (accountId, id) => {
  return $accounts.get(`${accountId}${apiEndpoints.blocklistProfiles}/${id}`);
};

/**
 * Updates the status of blocklist monitoring profile
 * @param {Object} id Blocklist monitoring profile id
 * @returns {Profile}
 */
export const updateBlocklistProfileStatus = (accountId, id, status) => {
  return $accounts.patch(
    `${accountId}${apiEndpoints.blocklistProfiles}/${id}/status/${status}`
  );
};
