/**
 * Gainsight track global directive, add this attribute to dom element you wanna track
 * @description Binds a custom [data-gs] attribute with its custom value with the Dom element
 * when it binds in the DOM.
 * @example v-track="track-me"
 */
const track = {
  bind: function(el, binding) {
    // Adds attributes to dom element
    el.setAttribute("data-gs", binding.value);
  },
};

/**
 * Global directives object
 */
const directives = {
  track,
};

export default directives;
