import { rejectPromise } from "@/utils";
import sortBy from "lodash/sortBy";
import { getStates, getCountries, getRoles, getTimezones } from "@/services";
import { hasProp, isEmpty } from "@/utils/common.utils";

/**
 * State of the module
 */
const state = {
  countries: [],
  states: [],
  timezones: [],
  roles: [],
};

/**
 * Mutations constants
 */
const SET_COUNTRIES = "SET_COUNTRIES";
const SET_STATES = "SET_STATES";
const SET_ROLES = "SET_ROLES";
const SET_TIMEZONES = "SET_TIMEZONES";

/**
 *  Mutations
 */
const mutations = {
  /**
   * SET_COUNTRIES
   * @description list of countries
   */
  [SET_COUNTRIES](state, data) {
    state.countries = data;
  },

  /**
   * SET_STATES
   * @description dynamic list of states
   */
  [SET_STATES](state, data) {
    state.states = data;
  },
  [SET_ROLES](state, data) {
    state.roles = data;
  },
  [SET_TIMEZONES](state, data) {
    state.timezones = data;
  },
};

/**
 *  Actions of the app module
 */
const actions = {
  /**
   * Fetched list of all the countries
   * @description Commits a mutattion to mutate the countries state property
   */
  async getCountries({ commit }, params = {}) {
    try {
      let { data } = await getCountries(params);

      data = data ?? [];
      commit(SET_COUNTRIES, sortBy(data, ["label"]));
    } catch (error) {
      rejectPromise(error);
    }
  },

  /**
   * Fetches list of states of a country
   * @param {String} Country whose states are to be fetched
   */
  async getStates({ commit }, country) {
    try {
      let { data } = await getStates(country);

      data = data ?? [];
      commit(SET_STATES, sortBy(data, ["label"]));
    } catch (error) {
      return rejectPromise(error);
    }
  },

  /**
   * Dispatches getcountries action to send api request to the api resource
   */
  getCountriesList({ dispatch }) {
    dispatch("getCountries");
  },
  /**
   * Fetches roles list
   */
  async getRoles({ commit }, params) {
    try {
      let { data } = await getRoles({ params });
      data = data ?? [];

      commit(SET_ROLES, data);
    } catch (error) {
      return rejectPromise(error);
    }
  },
  /**
   * Fetched timezones and commit the mutation to store the timezones in the state
   */
  async getTimezones({ commit }) {
    try {
      let { data } = await getTimezones();
      data = data ?? [];

      commit(SET_TIMEZONES, sortBy(data, ["name"]));
    } catch (error) {
      return rejectPromise(error);
    }
  },
};

const getters = {
  /**
   * Returns the list of all the countries in the state
   * @returns {Array} List of countries
   */
  countries({ countries }) {
    return countries;
  },
  /**
   * Returnns list of all the states of a country
   * @returns {Array}  List of states of a country
   */
  states({ states }) {
    return states;
  },

  timezones({ timezones }) {
    return timezones;
  },
  uniqTimezones({ timezones }) {
    if (isEmpty(timezones)) return [];

    const groupedZones = timezones.reduce((acc, { name, identifier }) => {
      identifier = identifier.toLowerCase();
      if (hasProp(acc, identifier)) acc[identifier].push(name);
      else acc[identifier] = [name];
      return acc;
    }, {});
    return Object.keys(groupedZones).map((identifier) => ({
      identifier,
      name: groupedZones[identifier].join(", "),
    }));
  },
  roles: ({ roles }) => {
    return roles;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
