import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import {
  injectInjectables,
  registerGlobalComponents,
  injectFilters,
  registerDirectives,
} from "./utils";
import store from "./store";
import "./components/global";
import "@/utils/date.utils.js";
import { isDevEnv } from "./utils";
import "@/plugins/dayjs";

Vue.config.productionTip = false;

/**
 * Enables vue devtools only in development enviroment
 */
Vue.config.devtools = isDevEnv();

/**
 * Injects injectables in the vue globally
 */
injectInjectables();

/**
 * Register global components
 */
registerGlobalComponents();

registerDirectives();

/**
 * Injects filters globally
 */
injectFilters();

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
