import { blocklistProfiles } from "@/services/blocklistProfiles";
import { rejectPromise } from "@/utils";

/**
|--------------------------------------------------
| State of the module
|--------------------------------------------------
*/
const state = {
  blocklistProfiles: [],
};

/**
|--------------------------------------------------
| Mutation constants
|--------------------------------------------------
*/
const SET_ACCOUNT_BLOCKLIST_PROFILES = "SET_ACCOUNT_BLOCKLIST_PROFILES";

/**
|--------------------------------------------------
| Mutations
|--------------------------------------------------
*/
const mutations = {
  [SET_ACCOUNT_BLOCKLIST_PROFILES](state, data) {
    state.blocklistProfiles = data;
  },
};

/**
|--------------------------------------------------
| Actions (Asynchronous)
|--------------------------------------------------
*/
const actions = {
  async getBlocklistProfiles({ commit }, params) {
    try {
      const { data } = await blocklistProfiles(params);
      commit(SET_ACCOUNT_BLOCKLIST_PROFILES, data);
    } catch (error) {
      return rejectPromise(error);
    }
  },
};

/**
|--------------------------------------------------
| Getters
|--------------------------------------------------
*/
const getters = {
  blocklistProfiles({ blocklistProfiles }) {
    return blocklistProfiles;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
