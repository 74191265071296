<template>
  <div ref="container">
    <span
      :id="'tooltip_' + _uid"
      class="text-ellipsis word-break body-2 text--primary"
      :class="textClass"
      v-if="text"
      @click="onClickHandler"
    >
      {{ text }}
      <v-tooltip top v-if="isOverflowing" :activator="'#tooltip_' + _uid">
        <span>{{ text }}</span>
      </v-tooltip>
    </span>
  </div>
</template>

<script>
/**
 * Implement ellipsis on the text
 */
export default {
  name: "TextEllipsis",
  /**
   * ---------- Data Attributes ----------------
   */
  data() {
    return {
      isOverflowing: false,
    };
  },
  /**
   * Watch property
   */
  watch: {
    text() {
      this.checkOverflow();
    },
  },
  /**
   * ---------------- Custom Events ------------------
   */
  emits: ["clicked"],
  /**
   * ---------------- Props ------------------
   */
  props: {
    text: {
      type: String,
      required: true,
    },
    /**
     * Class to be added at text dom element
     */
    textClass: { type: String, default: "" },
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    /**
     * Text wrapper click handler
     * @emits clicked
     */
    onClickHandler() {
      this.$emit("clicked");
    },

    /**
     * Check for overflow
     * @returns {Boolean}
     */
    checkOverflow() {
      this.$nextTick(() => {
        // Code that will run only after the
        this.isOverflowing = this.$refs.container?.offsetHeight > 30;
        // entire view has been rendered
      });
    },
  },
  /**
   * Mounted lifecycle
   */
  mounted() {
    this.checkOverflow();
  },
};
</script>

<style lang="scss" scoped>
.text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
